<template>
  <div class="page page-login">
    <div class="l-header">
      <div class="l-logo"></div>
      <span>飞逸美选</span>
    </div>
    <div class="l-content">
      <div class="l-form">
        <div class="l-control">
          <div class="l-input">
            <van-field
              v-model="phone"
              maxlength="11"
              type="tel"
              placeholder="请输入用户名"
            />
          </div>
        </div>
        <div class="l-control">
          <div class="l-input">
            <van-field
              v-model="code"
              maxlength="6"
              type="number"
              placeholder="请输入验证码"
            />
          </div>
          <div @click="handleVerify" class="l-verify-btn">
            <span>{{ verifyText }}</span>
          </div>
        </div>
        <div @click="handleSubmit" class="l-btn">
          <span>登录</span>
        </div>
      </div>
    </div>

    <div class="l-footer">
      <van-checkbox icon-size="16px" v-model="checked"
        >我已阅读并同意</van-checkbox
      >
      <p>
        <a
          href="https://res.feiyiyc.com/html/userServices.html"
          title="注册协议"
          >《注册协议》</a
        >
      </p>
      <p>
        <a
          href="https://res.feiyiyc.com/html/empower.html"
          title="《隐私用户授权协议》"
          >《隐私用户授权协议》</a
        >
      </p>
      <p>
        <a
          href="https://res.feiyiyc.com/html/privacy.html"
          title="《隐私权保护申明》"
          >《隐私权保护申明》</a
        >
      </p>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import API from "../../server/api";
export default {
  name: "Login",
  data() {
    return {
      checked: false,
      phone: "",
      code: "",
      countdown: 60,
      verifyText: "点击获取验证码",
      canSendCode: true,
    };
  },
  methods: {
    // 获取验证码
    async handleVerify() {
      const phone = this.phone;
      if (!this.canSendCode) return;
      if (!phone) {
        Toast("请输入手机号");
        return;
      }
      if (!/^1[3456789]\d{9}$/.test(phone)) {
        Toast("手机号格式有误");
        return;
      }
      try {
        const result = await API.loginSms({ phone });
        console.log(result, "获取验证码结果");
        if (result.success) {
          Toast("发送成功，请注意查收！");
          this.countTime();
        }
      } catch (err) {
        console.log(err);
        Toast("获取手机验证码失败");
      }
    },
    // 验证码倒计时
    countTime() {
      let { countdown } = this;
      if (this.countdown === 1) {
        this.verifyText = "点击获取验证码";
        this.canSendCode = true;
        this.countdown = 60;
        return false;
      } else {
        this.countdown = --countdown;
        this.canSendCode = false;
        this.verifyText = `${countdown}秒`;
      }
      if (window.TimeOutCount != null) clearTimeout(window.TimeOutCount);
      window.TimeOutCount = setTimeout(() => {
        this.countTime();
      }, 1000);
    },
    // 登录提交
    async handleSubmit() {
      if (!this.phone) {
        Toast("请输入手机号");
        return false;
      }
      if (!/^1[3456789]\d{9}$/.test(this.phone)) {
        Toast("手机号格式有误");
        return false;
      }
      if (!this.code) {
        Toast("请输入验证码");
        return false;
      }
      if (!this.checked) {
        Toast("请先阅读并同意协议");
        return false;
      }
      try {
        const { phone, code } = this;
        const form = {
          phone,
          code
        };
        const res = await API.login({ ...form });
        console.log(phone, "手机号");
        if (res.success) {
          const { userId, state } = res.data;
          console.log(userId, state);
          localStorage.setItem("userId", JSON.stringify(userId));
          localStorage.setItem("stock", state);
          localStorage.setItem("cellPhone", phone * 1);
          localStorage.setItem("formCachingInfo", JSON.stringify({}));
          localStorage.setItem("isItCached", JSON.stringify(false));
          this.$router.replace("/");
        }
      } catch (err) {
        console.log(err);
        Toast(err.message);
      }
    }
  }
};
</script>
<style lang="less" src="./index.less" scoped></style>
